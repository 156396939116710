import React from 'react';
import Layout from 'layout';
import Link, { StyledLink } from 'components/ui/link';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';

const ChatWithUs = styled.button`
  color: #0346c9;
  border: none;
  background-color: #fff;
  padding: 0;
  font-weight: 300;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSection = styled.section`
  ${media.mobile} {
    ${StyledLink} {
      font-size: 18px;
    }
  }
`;

const openChat = (e) => {
  e.preventDefault();
  /*eslint-disable */
  Intercom('show');
  /* eslint-enable */
};

const Contact = () => (
  <Layout
    metaTitle="Contact Us"
    canonical="/contact/"
    metaDescription="Need anything from the LiveSession team? Feel free to get in touch! We’re ready to answer all your session replay questions."
  >
    <StyledSection>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 text-left p-4">
            <h1 className="mb-4" style={{ fontSize: '1.65rem' }}>
              Contact us
            </h1>
            <p>
              <Link href="mailto:hello@livesession.io">
                <strong>hello@livesession.io</strong>
              </Link>
            </p>
            <p>
              <ChatWithUs id="openIntercomContact" onClick={openChat}>
                <strong>Chat with us</strong>
              </ChatWithUs>
            </p>
          </div>
          <div className="col-lg-6 text-left p-4">
            <h2 className="mb-4" style={{ fontSize: '1.65rem' }}>
              Office
            </h2>
            <p>LiveSession Sp. z.o.o (Ltd)</p>
            <p>gen. Wladyslawa Sikorskiego 3/2</p>
            <p>Atal Towers</p>
            <p>56-659, Wroclaw, Poland</p>
            <p>VAT ID: PL8971860301</p>
          </div>
        </div>
      </div>
    </StyledSection>
  </Layout>
);

export default Contact;
